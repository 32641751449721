<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">담당자 수정</div>
        <div class="description">
          담당자 수정를 등록합니다. <span class="text_red">(* 필수항목)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">담당자정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체명<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="partnercompany in partnerCompanys"
                        :key="partnercompany.id"
                        type="button"
                        :class="{
                          on: selectedpartnerCompany === partnercompany.id,
                        }"
                        @click="selectPartnerCompany(partnercompany.id)"
                      >
                        {{ partnercompany.companyName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>아이디<span class="text_red">*</span></th>
                  <td>{{ managerUserId }}</td>
                </tr>
                <tr>
                  <th>패스워드<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td validation passwordtoggle">
                      <input
                        :type="showPassword ? 'text' : 'password'"
                        class="w_250"
                        v-model="managerPw"
                        ref="managerPw"
                      />
                      <div class="inner_td">
                        <input
                          type="checkbox"
                          id="showPassword"
                          v-model="showPassword"
                        />
                        <label for="showPassword">비밀번호 보이기</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 이름<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="managerName"
                      ref="managerName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>담당자 직급(직책)<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="managerUserPosition"
                      ref="managerUserPosition"
                    />
                  </td>
                </tr>
                <tr>
                  <th>담당자 번호<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td mobile_number">
                      <select v-model="partnerManagerPhoneFirstPart">
                        <option>010</option>
                        <option>011</option>
                        <option>016</option>
                        <option>017</option>
                        <option>018</option>
                        <option>019</option>
                      </select>
                      -
                      <input
                        type="text"
                        class="w_55"
                        maxlength="4"
                        v-model="partnerManagerPhoneSecondPart"
                        ref="partnerManagerPhoneSecondPart"
                      />
                      -
                      <input
                        type="text"
                        class="w_55"
                        maxlength="4"
                        v-model="partnerManagerPhoneThirdPart"
                        ref="partnerManagerPhoneThirdPart"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_250"
                        v-model="selectedArea"
                        @change="handleAreaChange"
                        :disabled="mizeStatus === 'F' || mizeStatus === 'C'"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="area in areas"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.areaName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="특이사항을 입력하세요. "
                        v-model="specialMemo"
                        ref="specialMemo"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="checkChanges">수정</button>
            <button type="button" @click="goToManagerList">취소</button>
          </div>
        </article>
      </div>
      <div style="margin-top: 10px"></div>
      <CommentSection :parameterId="this.id" :boardType="'담당자'" />
    </div>
  </div>
  <!-- 변경된 이유 입력 모달  -->
  <ReasonModal
    v-if="isModalVisible3"
    @close-modal="closeModal"
    :isModalVisible3="isModalVisible3"
    :showModalForFields="showModalForFields"
    :currentFieldIndex="currentFieldIndex"
    @submit-reason="submitChangeReason"
  />
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
import CommentSection from "@/components/comments/CommentSection.vue";
import ReasonModal from "@/components/comments/ReasonModal.vue"; // 모달 컴포넌트 import

export default {
  name: "ManagersView",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      partnerCompanys: [], // 파트너 회사 목록을 저장할 배열
      selectedpartnerCompany: "", // 선택된 파트너 회사
      managerUserId: "",
      managerName: "",
      managerUserPosition: "",
      partnerManagerPhoneFirstPart: "010", // 담당자 휴대폰번호 첫 번째 부분
      partnerManagerPhoneSecondPart: "", // 담당자 휴대폰번호 두 번째 부분
      partnerManagerPhoneThirdPart: "", // 담당자 휴대폰번호 세 번째 부분
      specialMemo: "",
      showPassword: false, // 비밀번호 보이기 체크박스 상태

      // 기존 데이터
      originalData: {}, // fetchConstructionData()로 가져온 데이터를 저장

      // 변경된 항목에 대한 이유
      changeReasons: [], // 변경된 필드별 이유 저장
      showModalForFields: [], // 변경된 필드를 추적하여 모달창을 하나씩 띄우기 위한 배열
      isModalVisible3: false, // 모달창 표시 여부
      currentFieldIndex: 0, // 현재 모달창에서 보여줄 필드 인덱스

      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    CommentSection,
    ReasonModal,
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    areaName() {
      // 지역 ID를 기반으로 실제 표시할 이름 반환
      return (
        this.areas.find((area) => area.id === this.selectedArea)?.areaName ||
        "Unknown Area"
      );
    },
  },
  methods: {
    closeModal() {
      this.isModalVisible3 = false; // 모달을 닫기 위해 false로 설정
    },
    // 기존 데이터와 입력 데이터를 비교하는 메서드
    checkChanges() {
      this.showModalForFields = [];

      if (this.originalData.companyId !== this.selectedpartnerCompany) {
        this.showModalForFields.push("업체명");
      }

      if (this.originalData.userPw !== this.managerPw) {
        this.showModalForFields.push("패스워드");
      }

      if (this.originalData.userName !== this.managerName) {
        this.showModalForFields.push("담당자 이름");
      }

      if (this.originalData.userPosition !== this.managerUserPosition) {
        this.showModalForFields.push("담당자 직급(직책)");
      }

      if (
        this.originalData.userPhone !==
        `${this.partnerManagerPhoneFirstPart}-${this.partnerManagerPhoneSecondPart}-${this.partnerManagerPhoneThirdPart}`
      ) {
        this.showModalForFields.push("담당자 번호");
      }

      if (this.originalData.areaId !== this.selectedArea) {
        this.showModalForFields.push("지역");
      }

      if (this.originalData.specialMemo !== this.specialMemo) {
        this.showModalForFields.push("특이사항");
      }

      if (this.showModalForFields.length > 0) {
        this.isModalVisible3 = true;
        this.currentFieldIndex = 0;
      } else {
        // 변경된 필드가 없으면 바로 저장
        this.saveManager();
      }
    },
    // 현재 모달창에서 이유를 입력하고 다음 필드로 넘어가는 메서드
    submitChangeReason(reason) {
      const field = this.showModalForFields[this.currentFieldIndex];
      let originval = "";
      let newval = "";

      if (field === "업체명") {
        // originval = this.originalData.companyId;
        // newval = this.selectedpartnerCompany;

        // originalData에서 companyId 대신 companyName을 사용
        const originalCompany = this.partnerCompanys.find(
          (company) => company.id === this.originalData.companyId
        );
        const newCompany = this.partnerCompanys.find(
          (company) => company.id === this.selectedpartnerCompany
        );

        // 원래 회사 이름과 새로운 회사 이름을 사용
        originval = originalCompany ? originalCompany.companyName : "";
        newval = newCompany ? newCompany.companyName : "";
      }

      if (field === "패스워드") {
        originval = this.originalData.userPw;
        newval = this.managerPw;
      }

      if (field === "담당자 이름") {
        originval = this.originalData.userName;
        newval = this.managerName;
      }

      if (field === "담당자 직급(직책)") {
        originval = this.originalData.userPosition;
        newval = this.managerUserPosition;
      }

      if (field === "담당자 번호") {
        originval = this.originalData.userPhone;
        newval = `${this.partnerManagerPhoneFirstPart}-${this.partnerManagerPhoneSecondPart}-${this.partnerManagerPhoneThirdPart}`;
      }
      if (field === "지역") {
        // originalData의 지역 이름 찾기
        const originalArea = this.areas.find(
          (area) => area.id === this.originalData.areaId
        );
        const originalAreaName = originalArea
          ? originalArea.areaName
          : "데이터 없음";

        // 선택된 지역 이름 찾기
        const selectedArea = this.areas.find(
          (area) => area.id === this.selectedArea
        );
        const selectedAreaName = selectedArea
          ? selectedArea.areaName
          : "데이터 없음";

        originval = originalAreaName;
        newval = selectedAreaName;
      }
      if (field === "특이사항") {
        originval = this.originalData.specialMemo;
        newval = this.specialMemo;
      }

      // 배열에 { fieldName, reason } 객체를 추가
      this.changeReasons.push({
        ItemName: field,
        OriginValue: originval,
        NewValue: newval,
        Reason: reason,
      });

      // 다음 필드로 이동
      this.currentFieldIndex++;

      // 모든 필드를 처리했을 때
      if (this.currentFieldIndex >= this.showModalForFields.length) {
        this.isModalVisible3 = false;
        this.saveManager();
      }
    },
    async fetchPartnerCompanys() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.partnerCompanys = Array.isArray(data.data) ? data.data : [];
          console.log(this.partnerCompanys);
        }
      } catch (error) {
        console.error("Error fetching partner companys:", error);
      }
    },
    selectPartnerCompany(partnercompanyId) {
      this.selectedpartnerCompany = partnercompanyId;
    },
    goToManagerList() {
      this.$router.push({ path: "/managers/list" });
    },

    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async saveManager() {
      if (this.checkFields()) {
        const changeReasons = this.changeReasons.map((reason) => {
          // 만약 NewValue가 배열이라면 문자열로 변환
          if (Array.isArray(reason.NewValue)) {
            return {
              ...reason,
              NewValue: reason.NewValue.join("^"), // 배열을 문자열로 변환
            };
          }
          return reason;
        });

        try {
          const response = await apiClient.post("/api/Manager/manager-edit", {
            UserId: this.managerUserId,
            UserPw: this.managerPw,
            UserName: this.managerName,
            UserPosition: this.managerUserPosition,
            UserPhone: `${this.partnerManagerPhoneFirstPart}-${this.partnerManagerPhoneSecondPart}-${this.partnerManagerPhoneThirdPart}`,
            SpecialMemo: this.specialMemo,
            LoginId: localStorage.getItem("userid"),
            CompanyId: this.selectedpartnerCompany,
            AreaId: this.selectedArea,
            ManagerChangeReasons: changeReasons, //this.changeReasons, // 변경 사유 추가
            // 필요한 다른 필드 추가
          });
          if (response.status === 200) {
            console.log("담당자 정보가 성공적으로 수정되었습니다.");

            this.toast.success("담당자 정보가 성공적으로 수정되었습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
            this.goToManagerList();
          }
        } catch (error) {
          console.error("Error saving manager data:", error);
          this.toast.error("담당자 정보 수정 중 오류가 발생했습니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    checkFields() {
      if (!this.selectedpartnerCompany) {
        this.toast.error("소속 업체를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.managerName) {
        this.$refs.managerName.focus();
        this.toast.error("담당자명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.managerUserPosition) {
        this.$refs.managerUserPosition.focus();
        this.toast.error("담당자 직급(직책)을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.partnerManagerPhoneSecondPart) {
        this.$refs.partnerManagerPhoneSecondPart.focus();
        this.toast.error("담당자 상세번호(가운데자리)를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.partnerManagerPhoneThirdPart) {
        this.$refs.partnerManagerPhoneThirdPart.focus();
        this.toast.error("담당자 상세번호(마지막자리) 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.selectedArea === "0") {
        // 전체보기 (기본값) 또는 선택되지 않은 상태
        this.toast.error("지역을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      return true;
    },

    async fetchManagerData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post("/api/Manager/manager-info", {
          UserId: this.id,
          LoginId: localStorage.getItem("userid"),
        });

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.originalData = { ...data.data[0] }; // 첫 번째 데이터만 복사해 저장

          this.managerInfo = Array.isArray(data.data) ? data.data : [];

          this.selectedpartnerCompany = this.managerInfo[0].companyId;
          this.managerUserId = this.managerInfo[0].userId;
          this.managerPw = this.managerInfo[0].userPw;
          this.managerName = this.managerInfo[0].userName;
          this.managerUserPosition = this.managerInfo[0].userPosition;
          this.specialMemo = this.managerInfo[0].specialMemo;
          var phone = this.managerInfo[0].userPhone.split("-");

          this.partnerManagerPhoneFirstPart = phone[0];
          this.partnerManagerPhoneSecondPart = phone[1];
          this.partnerManagerPhoneThirdPart = phone[2];

          this.selectedArea = this.managerInfo[0].areaId;
        }
      } catch (error) {
        console.error("Error fetching manager info data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchPartnerCompanys(); // 소속업체 목록을 가져옴
    this.fetchManagerData();
  },
};
</script>
