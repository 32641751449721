<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">프로젝트 등록 (외주)</div>
        <div class="description">
          프로젝트건 (외주) 을 등록합니다.
          <span class="text_red">(* 필수항목)</span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">프로젝트정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="프로젝트명을 입력해주세요"
                      v-model="projectName"
                      ref="projectName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>프로젝트금액(vat별도)<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250 amount-input"
                      placeholder="프로젝트금액을 입력해주세요"
                      v-model="formattedRealAmount"
                      ref="formattedRealAmount"
                      @input="formatRealAmount"
                    />
                  </td>
                </tr>
                <tr>
                  <th>추가금액(vat별도)</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250 amount-input"
                        placeholder="추가금액을 입력해주세요"
                        v-model="formattedExtraAmount"
                        ref="formattedExtraAmount"
                        @input="formatExtraAmount"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_250"
                        v-model="selectedArea"
                        @change="handleAreaChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="area in areas"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.areaName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트시작일<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedStartDate"
                        @focus="resetStartDate"
                        @change="addStartDate"
                      />
                    </div>
                    <!-- 선택된 프로젝트 시작일 목록 -->
                    <div
                      v-for="(date, index) in selectedStartDates"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{ date }} ({{ getDayOfWeek(date) }})</span>
                      <button type="button" @click="removeStartDate(index)">
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>프로젝트상세내용</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="상세설명을 입력하세요. "
                        v-model="detailDesc"
                        ref="detailDesc"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>투입자재목록<span class="text_red">*</span></th>
                  <td>
                    <button
                      type="button"
                      :class="{ on: !showMaterialDropdown }"
                      @click="toggleMaterialDropdown"
                      style="margin-bottom: 5px"
                    >
                      투입자재 없음
                    </button>
                    <div class="inner_td" v-if="showMaterialDropdown">
                      <!-- 자재 선택 dropdown -->
                      <multiselect
                        v-model="selectedMaterial"
                        :options="materials"
                        :custom-label="customMaterialLabel"
                        track-by="id"
                        placeholder="자재를 검색하세요"
                        @select="addMaterial"
                        :searchable="true"
                        :close-on-select="true"
                        :allow-empty="false"
                      />
                    </div>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <button
                        type="button"
                        @click="decreaseQuantityByTen(index)"
                      >
                        -10
                      </button>
                      <button type="button" @click="decreaseQuantity(index)">
                        -
                      </button>
                      <span>{{ material.quantity }}</span>
                      <button type="button" @click="increaseQuantity(index)">
                        +
                      </button>
                      <button
                        type="button"
                        @click="increaseQuantityByTen(index)"
                      >
                        +10
                      </button>
                      <button type="button" @click="removeMaterial(index)">
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="내용을 입력하세요. "
                        v-model="addMemo"
                        ref="addMemo"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (영업)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="salesmanager in salesManagers"
                        :key="salesmanager.userId"
                        type="button"
                        :class="{
                          on: selectedSalesManager === salesmanager.userId,
                        }"
                        @click="selectSalesManager(salesmanager.userId)"
                      >
                        {{ salesmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (PM)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="pmmanager in pmManagers"
                        :key="pmmanager.userId"
                        type="button"
                        :class="{ on: selectedPmManager === pmmanager.userId }"
                        @click="selectPmManager(pmmanager.userId)"
                      >
                        {{ pmmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">확인 정보 (수주 후에 확인가능한 정보)</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <!-- 고객사 상호 // 수주한 사람한테만 보임 수주하기전에도 칸은 표시하고 “수주 후에 확인 가능” 이라고 표시하기 -->
                <tr>
                  <th>고객사 상호<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="고객사 상호명을 입력해주세요"
                      v-model="customerName"
                      ref="customerName"
                    />
                  </td>
                </tr>
                <!-- 상세주소 // 수주한 사람한테만 보임 > 수주하기전에도 칸은 표시하고 “수주 후에 확인 가능” 이라고 표시하기 -->
                <tr>
                  <th>상세주소<span class="text_red">*</span></th>
                  <td class="td_address">
                    <div>
                      <input
                        type="text"
                        class="w_150"
                        placeholder="우편번호"
                        v-model="postalCode"
                        ref="postalCode"
                        readonly
                      />
                      <button
                        type="button"
                        class="keep"
                        @click="openPostcodePopup"
                      >
                        주소찾기
                      </button>
                    </div>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="주소"
                      v-model="address"
                      ref="address"
                    />
                    <input
                      type="text"
                      class="w_250"
                      placeholder="상세주소"
                      v-model="detailedAddress"
                      ref="detailedAddress"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placeName"
                      ref="placeName"
                      placeholder="현장명을 입력해주세요"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 이름<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placemanagerName"
                      ref="placemanagerName"
                      placeholder="현장 담당자 이름을 입력해주세요"
                    />
                  </td>
                </tr>
                <tr>
                  <th>현장 담당자 연락처<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      v-model="placemanagerMobile"
                      ref="placemanagerMobile"
                      @input="formatPhoneNumber"
                      placeholder="현장 담당자 연락처를 입력해주세요"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">사진</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>사진</th>
                  <td>
                    <div class="input-box">
                      <button
                        type="button"
                        class="keep"
                        @click="triggerFileInput('photo')"
                      >
                        + 사진 추가
                      </button>
                      <input
                        type="file"
                        ref="photoInput"
                        accept="image/*"
                        multiple
                        style="display: none"
                        @change="handleFileChange('photo', $event)"
                      />
                      <div
                        v-for="(file, index) in photoPreviews"
                        :key="index"
                        class="uploaded-image-wrapper"
                      >
                        <img
                          :src="file"
                          alt="Uploaded File"
                          class="uploaded-image"
                        />
                      </div>
                    </div>
                    <div class="photo-preview" v-if="photos.length">
                      <!-- <div
                        class="photo-item"
                        v-for="(photo, index) in photos.slice(0, 5)"
                        :key="index"
                        @click="openImageViewer(index)"
                      > -->
                      <div
                        class="photo-item"
                        v-for="(photo, index) in photos"
                        :key="index"
                        @click="openImageViewer(index)"
                      >
                        <img :src="photo.url" alt="Uploaded photo" />

                        <button
                          @click.stop="removeFile(index)"
                          class="delete-button"
                        >
                          x
                        </button>

                        <!-- <div
                          v-if="index === 4 && photos.length > 5"
                          class="photo-count-overlay"
                        >
                          +{{ photos.length - 5 }} 장
                        </div> -->
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style="display: none">
                  <th>첨부파일</th>
                  <td>
                    <div class="input-box">
                      <button
                        type="button"
                        class="keep"
                        @click="triggerFileInput('file')"
                      >
                        + 파일 추가
                      </button>
                      <input
                        type="file"
                        ref="fileInput"
                        multiple
                        style="display: none"
                        @change="handleFileChange('file', $event)"
                      />
                    </div>
                    <ul class="file-list" v-if="files.length">
                      <li v-for="(file, index) in files" :key="index">
                        {{ file.name }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button type="button" class="on" @click="saveConstruction">
              <!-- saveConstruction  -->
              등록
            </button>
            <button type="button" @click="goToConstructionList">취소</button>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 100px"></div>
    </div>
  </div>

  <!-- 이미지 뷰어 모달 -->
  <div
    v-if="isViewerOpen"
    class="modal"
    @mousedown="startDrag"
    @mousemove="dragging"
    @mouseup="endDrag"
    @touchstart="startDrag"
    @touchmove="dragging"
    @touchend="endDrag"
  >
    <div class="modal-content">
      <!-- 맨 처음 이미지가 아니면 ← 버튼 표시 -->
      <button v-if="currentIndex > 0" @click="prevImage" class="arrow">
        ←
      </button>
      <img :src="currentImage2" alt="Preview" />
      <!-- 맨 끝 이미지가 아니면 → 버튼 표시 -->
      <button
        v-if="currentIndex < photos.length - 1"
        @click="nextImage"
        class="arrow"
      >
        →
      </button>
    </div>
    <button @click="closeViewer">닫기</button>
  </div>
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import imageCompression from "browser-image-compression";

export default {
  name: "ProjectAdd",
  components: {
    Multiselect,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      photos: [], // 업로드된 사진 목록
      photoPreviews: [], // 이미지 미리보기 URL 저장

      files: [], // 업로드된 파일 목록

      isViewerOpen: false, // 이미지 뷰어 상태
      currentImage: null, // 현재 보고 있는 이미지
      currentIndex: 0, // 현재 이미지 인덱스
      startX: 0, // 드래그 시작 위치
      deltaX: 0, // 드래그 이동 거리
      draggingActive: false, // 드래그 상태 플래그

      showMaterialDropdown: true, // 자재 선택 dropdown을 표시할지 여부
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: null, // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 프로젝트 시작일
      isDateInitialized: false, // 플래그로 처음 클릭을 구분
      selectedStartDates: [], // 선택된 프로젝트 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      extraAmount: 0, // 실제 금액을 저장하는 변수
      formattedExtraAmount: "", // ','가 포함된 금액을 저장하는 변수
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수
      checkdatedevice: 0,
      placemanagerMobile: "",
      placemanagerName: "",
      placeName: "",
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    currentImage2() {
      return this.photos[this.currentIndex]?.url || "";
    },
  },
  methods: {
    triggerFileInput(type) {
      if (type === "photo") this.$refs.photoInput.click();
      if (type === "file") this.$refs.fileInput.click();
    },
    handleFileChange(type, event) {
      const selectedFiles = Array.from(event.target.files);
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ]; // 허용할 이미지 타입

      if (type === "photo") {
        selectedFiles.forEach((file) => {
          if (!validImageTypes.includes(file.type)) {
            this.toast.error(
              `"${file.name}"은(는) 지원하지 않는 파일 형식입니다.\n이미지 파일만 업로드 가능합니다.`,
              {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              }
            );
          } else {
            const isDuplicate = this.photos.some(
              (photo) => photo.name === file.name
            );
            if (!isDuplicate) {
              const reader = new FileReader();
              reader.onload = (e) => {
                this.photos.push({
                  name: file.name,
                  url: e.target.result,
                  isLocal: true,
                });
              };
              reader.readAsDataURL(file);
            } else {
              this.toast.error(`${file.name}은 이미 추가된 파일입니다.`, {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              });
            }
          }
        });
      }
    },
    removeFile(index) {
      // 파일 미리보기와 실제 파일 모두 삭제
      this.photoPreviews.splice(index, 1);
      this.photos.splice(index, 1);
    },
    openImageViewer(index) {
      this.currentIndex = index;
      this.currentImage = this.photos[index].url;
      this.isViewerOpen = true;
    },
    closeViewer() {
      this.isViewerOpen = false;
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentImage = this.photos[this.currentIndex].url;
      }
    },
    nextImage() {
      if (this.currentIndex < this.photos.length - 1) {
        this.currentIndex++;
        this.currentImage = this.photos[this.currentIndex].url;
      }
    },

    startDrag(event) {
      this.draggingActive = true;
      this.startX =
        event.type === "mousedown" ? event.clientX : event.touches[0].clientX;
    },
    dragging(event) {
      if (!this.draggingActive) return;
      this.deltaX =
        (event.type === "mousemove"
          ? event.clientX
          : event.touches[0].clientX) - this.startX;
    },
    endDrag() {
      if (!this.draggingActive) return;
      this.draggingActive = false;

      if (this.deltaX > 50) {
        // 오른쪽에서 왼쪽으로 드래그
        this.prevImage();
      } else if (this.deltaX < -50) {
        // 왼쪽에서 오른쪽으로 드래그
        this.nextImage();
      }
      this.deltaX = 0; // 초기화
    },
    toggleMaterialDropdown() {
      this.showMaterialDropdown = !this.showMaterialDropdown; // 자재 선택 dropdown 보임/숨김 상태 전환
    },
    // 전화번호 포맷팅 함수
    formatPhoneNumber(event) {
      let input = event.target.value.replace(/\D/g, ""); // 숫자 이외의 문자 제거

      // 변환된 값을 담을 변수 선언
      let formattedNumber = "";

      if (input.length <= 3) {
        formattedNumber = input;
      } else if (input.length <= 7) {
        formattedNumber = input.slice(0, 3) + "-" + input.slice(3);
      } else {
        formattedNumber =
          input.slice(0, 3) +
          "-" +
          input.slice(3, 7) +
          "-" +
          input.slice(7, 11);
      }

      // 포맷팅된 값을 다시 반영
      this.placemanagerMobile = formattedNumber;
    },
    getFormattedDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    resetStartDate() {
      // 날짜가 처음 클릭되었을 때만 초기화
      if (!this.isDateInitialized) {
        this.selectedStartDate = null; // 날짜 초기화
        this.isDateInitialized = true; // 이후로는 초기화하지 않도록 설정
      }
    },
    customMaterialLabel(option) {
      return `${option.materialsName} ${
        option.modelName ? `(${option.modelName})` : ""
      }`;
    },
    formatRealAmount(event) {
      // 입력된 값에서 숫자만 추출
      const rawValue = event.target.value.replace(/[^0-9]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.realAmount = parseInt(rawValue, 10) || 0;

      // 천 단위로 ',' 추가
      this.formattedRealAmount = this.realAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatExtraAmount(event) {
      let rawValue = event.target.value;

      // 입력 값이 '-'만 있으면 그대로 유지
      if (rawValue === "-") {
        this.formattedExtraAmount = rawValue;
        return;
      }

      // 입력된 값에서 숫자 및 음수 기호(-)만 추출
      rawValue = rawValue.replace(/[^0-9-]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.extraAmount = parseInt(rawValue, 10) || 0;

      // 음수 기호 유지한 상태로 천 단위로 ',' 추가
      this.formattedExtraAmount = this.extraAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectPmManager(pmmanagerId) {
      this.selectedPmManager = pmmanagerId;
    },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    getDayOfWeek(dateString) {
      //console.log("Received dateString:", dateString);
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.log("Invalid date:", dateString);
        return "유효하지 않은 날짜";
      }
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      const day = date.getDay();
      return daysOfWeek[day];
    },

    // 프로젝트 시작일 추가
    addStartDate(event) {
      this.checkdatedevice++;
      const dateValue = event.target.value;
      const today = this.getFormattedDate();

      if (dateValue >= today) {
        if (this.selectedStartDate) {
          // 이미 선택된 날짜가 있는지 확인
          const exists = this.selectedStartDates.includes(
            this.selectedStartDate
          );

          if (!exists) {
            // 선택된 날짜가 없으면 추가
            this.selectedStartDates.push(this.selectedStartDate);
            // 추가 후 날짜를 빠른 순으로 정렬
            this.selectedStartDates.sort((a, b) => new Date(a) - new Date(b));
          } else {
            this.toast.error("이미 선택된 프로젝트 시작일입니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }

          // 선택 후 초기화
          this.selectedStartDate = "";
        }
      } else {
        console.log(this.checkdatedevice);
        this.toast.error("프로젝트 등록 시 과거 날짜는 선택할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        // 선택 후 초기화
        this.selectedStartDate = "";
      }
    },
    // 프로젝트 시작일 제거
    removeStartDate(index) {
      this.selectedStartDates.splice(index, 1);
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    addMaterial() {
      if (this.selectedMaterial) {
        const exists = this.selectedMaterials.find(
          (mat) => mat.id === this.selectedMaterial.id
        );
        if (!exists) {
          this.selectedMaterials.push({
            ...this.selectedMaterial,
            quantity: 1, // 기본 수량 1로 설정
          });
          this.selectedMaterial = null; // 선택 후 초기화
          console.log("Selected Materials:", this.selectedMaterials);
        } else {
          this.toast.error("이미 선택된 자재입니다.", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
        }
      }
    },
    increaseQuantity(index) {
      this.selectedMaterials[index].quantity++;
    },
    // 새로운 수량 10 증가 메소드
    increaseQuantityByTen(index) {
      this.selectedMaterials[index].quantity += 10;
    },
    decreaseQuantityByTen(index) {
      if (this.selectedMaterials[index].quantity > 10) {
        this.selectedMaterials[index].quantity -= 10;
      } else {
        this.toast.error("현재 10 이하의 수량으로 더 이상 줄일 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    decreaseQuantity(index) {
      if (this.selectedMaterials[index].quantity > 1) {
        this.selectedMaterials[index].quantity--;
      } else {
        this.toast.error("1개 미만 수량으로 선택할 수 없습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
      }
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1);
    },
    handleAreaChange() {
      this.currentPage = 1; // 지역 변경 시 페이지를 1로 리셋
      //this.fetchData(); // 지역 선택 시 데이터 갱신
    },
    goToConstructionList() {
      this.$router.push({ path: "/projects/list" });
    },
    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async uploadImages() {
      try {
        const formData = new FormData();

        for (const image of this.photos) {
          try {
            // 1. 로컬 파일 처리
            if (image.isLocal) {
              // 이미지 데이터 추출
              const base64Data = image.url.split(",")[1]; // Base64 데이터 추출
              const binaryData = atob(base64Data); // Base64를 바이너리로 디코딩
              const arrayBuffer = new Uint8Array(binaryData.length);

              for (let i = 0; i < binaryData.length; i++) {
                arrayBuffer[i] = binaryData.charCodeAt(i);
              }

              const blob = new Blob([arrayBuffer], { type: "image/jpeg" }); // Blob 생성

              // 이미지 압축 옵션 설정
              const options = {
                maxSizeMB: 1, // 1MB 이하로 압축 // // 이미지 용량 줄이는 설정이며 현재는 1MB (500KB => 0.5 / 250KB => 0.25로 설정하면 되며, 너무 줄일경우 화질 크게 손상되니 적당히 줄일것)
                maxWidthOrHeight: 1024, // 최대 너비 또는 높이
                useWebWorker: true,
              };

              // 이미지 압축
              const compressedBlob = await imageCompression(blob, options);

              // 2. 파일 이름에 타임스탬프 추가
              const now = new Date();
              const timestamp = now
                .toISOString()
                .replace(/[-:.TZ]/g, "")
                .slice(0, 17); // YYYYMMDDHHmmssSSS 형식
              const newFileName = `${timestamp}_${image.name}`;

              const compressedFile = new File([compressedBlob], newFileName, {
                type: "image/jpeg",
              });

              // FormData에 추가
              formData.append(image.name, compressedFile);
              image.newname = newFileName;
            }
          } catch (error) {
            console.error("이미지 압축 오류:", error);
            this.toast.error("이미지 압축 중 오류가 발생했습니다.", {
              position: "top-center",
              timeout: 1500,
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true,
              closeButton: false,
            });
            return false;
          }
        }

        // 서버로 업로드 요청
        const response = apiClient.post(
          "/api/Construction/construction-photo-upload-add",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          this.toast.success("이미지가 성공적으로 업로드되었습니다!", {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          });
          console.log(response.data);
        }
        return true;
      } catch (error) {
        console.error("이미지 업로드 중 오류 발생:", error);
        this.toast.error("이미지 업로드 중 오류가 발생했습니다.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
    },
    async saveConstruction() {
      if (this.checkFields()) {
        if (await this.uploadImages()) {
          // 프로젝트 시작일을 '^'로 구분된 문자열로 변환
          const formattedStartDates = this.selectedStartDates.join("^");

          const sendImageNameList = this.photos
            .map((photo) => photo.newname)
            .join("^");

          console.log("★--ProjectName--" + this.projectName);
          console.log("★--Price--" + this.formattedRealAmount);
          console.log("★--AddPrice--" + this.formattedExtraAmount);
          console.log("★--StartDate--" + formattedStartDates);
          console.log("★--AreaId--" + this.selectedArea);
          console.log("★--ZipCode--" + this.postalCode);
          console.log("★--Addr1--" + this.address);
          console.log("★--Addr2--" + this.detailedAddress);
          console.log("★--DetailContent--" + this.detailDesc);
          console.log(
            "★--Materials--" + JSON.stringify(this.selectedMaterials, null, 2)
          );
          console.log("★--EtcMemo--" + this.addMemo);
          console.log("★--MizeSalesUserId--" + this.selectedSalesManager);
          console.log("★--MizePMUserId--" + this.selectedPmManager);
          console.log("★--LoginId--" + localStorage.getItem("userid"));

          try {
            const response = await apiClient.post(
              "/api/Construction/construction-add",
              {
                ProjectName: this.projectName,
                Price: this.formattedRealAmount,
                AddPrice: this.formattedExtraAmount,
                StartDate: formattedStartDates,
                AreaId: this.selectedArea,
                ZipCode: this.postalCode,
                Addr1: this.address,
                Addr2: this.detailedAddress,
                DetailContent: this.detailDesc,
                Materials: this.selectedMaterials.map((mat) => ({
                  MaterialId: mat.id,
                  MaterialCnt: mat.quantity,
                })),
                EtcMemo: this.addMemo,
                MizeSalesUserId: this.selectedSalesManager,
                MizePMUserId: this.selectedPmManager,
                CustomerName: this.customerName,
                LoginId: localStorage.getItem("userid"),
                // 필요한 다른 필드 추가
                PlaceManagerMobile: this.placemanagerMobile,
                PlaceManagerName: this.placemanagerName,
                PlaceName: this.placeName,
                MaterialsNoYN: this.showMaterialDropdown == true ? "N" : "Y",
                ImageNameList: sendImageNameList,
              }
            );
            if (response.status === 200) {
              this.toast.success("프로젝트 정보가 성공적으로 등록되었습니다.", {
                position: "top-center", // 메시지 위치
                timeout: 1500, // 3초 동안 표시
                closeOnClick: true,
                pauseOnHover: true,
                hideProgressBar: true, // 진행 표시줄 숨기기
                closeButton: false, // X 버튼 숨기기
              });
              //this.g
              this.goToConstructionList();
            }
          } catch (error) {
            console.error("Error saving construction data:", error);
            this.toast.error("프로젝트 정보가 등록 중 오류가 발생했습니다.", {
              position: "top-center", // 메시지 위치
              timeout: 1500, // 3초 동안 표시
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true, // 진행 표시줄 숨기기
              closeButton: false, // X 버튼 숨기기
            });
          }
        }
      }
    },
    checkFields() {
      if (!this.projectName) {
        this.$refs.projectName.focus();
        this.toast.error("프로젝트명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });

        return false;
      }
      if (!this.formattedRealAmount) {
        this.$refs.formattedRealAmount.focus();
        this.toast.error("프로젝트금액을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedArea === "0") {
        // 전체보기 (기본값) 또는 선택되지 않은 상태
        this.toast.error("지역을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (this.selectedStartDates.length === 0) {
        // 프로젝트 시작일이 선택되지 않았을 때
        this.toast.error("프로젝트 시작일을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (this.showMaterialDropdown && this.selectedMaterials.length === 0) {
        // 투입자재목록이 선택되지 않았을 때
        this.toast.error("투입자재목록을 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.selectedSalesManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(영업)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.selectedPmManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        this.toast.error("담당자(PM)를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }
      if (!this.customerName) {
        this.$refs.customerName.focus();
        // 고객사 상호가 입력되지 않았을 때
        this.toast.error("고객사 상호를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.detailedAddress) {
        this.$refs.detailedAddress.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("상세주소를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placeName) {
        this.$refs.placeName.focus();

        this.toast.error("현장명을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placemanagerName) {
        this.$refs.placemanagerName.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("현장 담당자 이름을 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      if (!this.placemanagerMobile) {
        this.$refs.placemanagerMobile.focus();
        // 상세주소가 입력되지 않았을 때
        this.toast.error("현장 담당자 연락처를 입력해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return false;
      }

      return true;
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴

    console.log("Fetched materials: ", JSON.stringify(this.materials, null, 2));
  },
};
</script>

<style scoped>
/* 기본 입력 필드 오른쪽 정렬 */
input.amount-input {
  text-align: right; /* 입력값은 오른쪽 정렬 */
}

/* placeholder 왼쪽 정렬 */
input.amount-input::placeholder {
  text-align: left; /* placeholder는 왼쪽 정렬 */
}

/* 기타 스타일 */
.material-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.material-item button {
  margin: 0 5px;
  padding: 5px;
}

.material-item span {
  margin-right: 10px;
}
.multiselect__input {
  position: relative;
  z-index: 1;
}

.multiselect__content {
  position: absolute;
  top: calc(100% + 5px); /* 입력 필드 바로 아래로 위치시킴 */
  z-index: 9999;
}
</style>
