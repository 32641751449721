<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">담당자 목록</div>
        <div class="description">담당자 정보를 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="userType === 'M'">
                  <th>업체별</th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_200"
                        v-model="selectedCompany"
                        @change="handleCompanyChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="company in companies"
                          :key="company.id"
                          :value="company.id"
                        >
                          {{ company.companyName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>계정상태별</th>
                  <td>
                    <div class="inner_td">
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'A' }"
                        @click="updateStatus('A')"
                      >
                        전체보기
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'Y' }"
                        @click="updateStatus('Y')"
                      >
                        삭제
                      </button>
                      <button
                        type="button"
                        :class="{ on: SearchStatus === 'N' }"
                        @click="updateStatus('N')"
                      >
                        미삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="SearchType">
                        <option value="managerName">담당자명</option>
                        <option value="managerPhone">담당자번호</option>
                      </select>
                      <input
                        type="text"
                        v-model="SearchKey"
                        class="pop_text"
                        placeholder="검색어를 입력해주세요"
                        @keyup.enter="handleSearchKeyEnter"
                      />
                      <button type="button" @click="handleSearchKeyEnter">
                        검색
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span
                class="accent_color bold"
                style="margin-left: 2px; margin-right: 2px"
              >
                {{ this.totalManagers }}
              </span>
              <span>건</span>
              <select v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option selected="selected" value="5">5</option>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option></select
              ><span>건씩</span>
            </div>
            <button
              type="button"
              v-if="userType === 'M'"
              @click="goToManagerAdd"
            >
              등록하기
            </button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div
              v-if="managerList.length > 0"
              class="tbl_list table_m mobile_table"
            >
              <table>
                <colgroup>
                  <col v-if="userType === 'M'" style="width: 5%" />
                  <col style="width: 5%" />
                  <col style="width: 10%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 12%" />
                  <col style="width: 10%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th v-if="userType === 'M'">
                      <input
                        type="checkbox"
                        v-model="allSelected"
                        @change="toggleAllSelections"
                      />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">업체명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">담당자명(지역)</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">담당자번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <!-- <th>
                      <a href="###">계정생성일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th> -->
                    <th>
                      <a href="###">수주횟수</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="manager in paginatedManagers"
                    :key="manager.userId"
                  >
                    <td>
                      <input
                        type="checkbox"
                        @change="toggleSelection(manager.userId)"
                        :checked="selectedManagers.includes(manager.userId)"
                      />
                    </td>
                    <td class="no_mobile">{{ manager.no }}</td>
                    <td class="seat o3">
                      <span class="only_mobile">업체명 : </span>
                      <p class="long_p">{{ manager.companyName }}</p>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div>
                        <router-link
                          v-if="userType === 'M'"
                          :to="{
                            name: 'ManagersView',
                            params: { id: manager.userId },
                          }"
                          class="center_td"
                        >
                          <p class="text_bold long_p">
                            {{ manager.userName }}
                            {{
                              manager.areaName
                                ? "(" + manager.areaName + ")"
                                : ""
                            }}
                          </p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="name o5r">
                      <span class="only_mobile">담당자번호 : </span>
                      <span>{{
                        manager.userPhone ? manager.userPhone : "미정"
                      }}</span>
                    </td>
                    <!-- <td class="name o5">
                      <span class="only_mobile">계정생성일 : </span>
                      <span>{{
                        manager.createDtm ? manager.createDtm : "미정"
                      }}</span>
                    </td> -->
                    <td class="name o5">
                      <span class="only_mobile">수주횟수 : </span>
                      <span
                        class="clickable"
                        @click="goToProjectList(manager.companyId)"
                        style="
                          cursor: pointer;
                          text-decoration: underline;
                          color: blue;
                        "
                        ><b>{{ manager.projectCnt }}</b></span
                      >
                    </td>
                    <td class="center_td o_stat">
                      <div :class="getStatusClass(manager.delYn)">
                        <span>{{ getStatusText(manager.delYn) }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 6%" />
                  <col style="width: 11%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 12%" />
                  <col style="width: 10%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">업체명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">담당자명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">담당자번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <!-- <th>
                      <a href="###">계정생성일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th> -->
                    <th>
                      <a href="###">수주횟수</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_data">
                    <td colspan="7">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div v-if="userType === 'M'">
              <a @click.prevent="deleteSelected">삭제</a>
              <a @click.prevent="nodeleteSelected">미삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a
                href="###"
                v-if="hasPrevPageGroup"
                @click.prevent="prevPageGroup"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###" v-if="hasPrevPage" @click.prevent="prevPage"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a
                v-for="page in pageGroup"
                :key="page"
                href="###"
                :class="{ on: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page }}
              </a>
              <a href="###" v-if="hasNextPage" @click.prevent="nextPage"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a
                href="###"
                v-if="hasNextPageGroup"
                @click.prevent="nextPageGroup"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
      <!-- 삼성인터넷브라우저에서 안되서 원인 파악하다가 스타일문제로 확인! 임시방편임 -->
      <div style="margin-top: 200px"></div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스
import { useToast } from "vue-toastification";

export default {
  name: "ManagersList",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      managerList: [], // 담당자 목록 데이터를 저장할 배열
      selectedManagers: [], // 선택된 담당자들의 id를 저장할 배열
      allSelected: false, // thead 체크박스 상태
      currentPage: 1, // 현재 페이지
      itemsPerPage: 10, // 페이지 당 항목 수
      pageGroupSize: 5, // 하단 페이지 나오는 수 ( 1-5 / 6-10 > 5개씩 )
      SearchType: "managerName", // 검색 키 (dropdownlist)
      SearchKey: "", // 검색 textbox 값
      SearchStatus: "A", // 진열 상태별값
      OrderType: "", // 이름 오름차순
      totalManagers: 0, // 총 담당자 수
      companies: [], // API에서 가져온 회사 목록을 저장할 배열
      selectedCompany: "0", // 전체보기 기본 값 설정
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
    // 현재 페이지에 해당하는 회사 목록을 반환
    paginatedManagers() {
      if (!Array.isArray(this.managerList)) {
        return [];
      }

      const start = 0;
      const end = this.itemsPerPage;
      console.log(this.managerList);
      console.log(
        "Paginated managerList: ",
        this.managerList.slice(start, end)
      );
      return this.managerList.slice(start, end);
    },
    // 총 페이지 수 계산
    totalPages() {
      return Math.ceil(this.totalManagers / this.itemsPerPage);
    },
    // 현재 페이지 그룹 계산
    pageGroup() {
      const start =
        Math.floor((this.currentPage - 1) / this.pageGroupSize) *
          this.pageGroupSize +
        1;
      const end = Math.min(start + this.pageGroupSize - 1, this.totalPages);
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    hasPrevPageGroup() {
      //return this.currentPage > this.pageGroupSize;
      return this.currentPage > 1;
    },
    hasNextPageGroup() {
      //return this.currentPage + this.pageGroupSize <= this.totalPages;
      return this.currentPage < this.totalPages;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },

  methods: {
    goToProjectList(companyId) {
      // 회사 ID를 파라미터로 전달하여 라우팅
      this.$router.push({
        path: "/projects/list",
        query: {
          company_id: companyId,
        },
      });
    },
    getFormattedStartDate(startDate) {
      if (!startDate) {
        return "";
      }

      // 날짜 문자열을 '^'로 분리
      const dates = startDate.split("^");

      // 첫 번째 날짜는 그대로 표시하고, 나머지 날짜가 있다면 "(선택가능)" 추가
      if (dates.length > 1) {
        return `${dates[0]} (선택가능)`;
      }

      return dates[0]; // 첫 번째 날짜만 반환
    },

    // 상태에 따른 문구 반환
    getStatusText(status) {
      switch (status) {
        case "N":
          return "미삭제";
        case "Y":
          return "삭제";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "N":
          return "stat back_green"; // 미삭제
        case "Y":
          return "stat back_red"; // 삭제
        default:
          return "stat back_default"; // 기본 값
      }
    },
    handleSearchKeyEnter() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.managerList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },

    toggleAllSelections() {
      if (this.allSelected) {
        this.selectedManagers = this.managerList.map(
          (manager) => manager.userId
        );
      } else {
        this.selectedManagers = [];
      }
    },
    toggleSelection(managerId) {
      const index = this.selectedManagers.indexOf(managerId);

      if (index > -1) {
        this.selectedManagers.splice(index, 1);
      } else {
        this.selectedManagers.push(managerId); // 기존 코드를 이 줄로 수정
      }
    },
    nodeleteSelected() {
      if (this.selectedManagers.length === 0) {
        this.toast.error(
          "원하시는 미삭제 처리할 담당자 리스트를 선택해주세요.",
          {
            position: "top-center", // 메시지 위치
            timeout: 1500, // 3초 동안 표시
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: true, // 진행 표시줄 숨기기
            closeButton: false, // X 버튼 숨기기
          }
        );
        return;
      }
      if (confirm("선택된 담당자 항목을 미삭제 로 변경하시겠습니까?")) {
        this.NoDeleteManager(this.selectedManagers);
      }
    },
    async NoDeleteManager(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Manager/manager-no-delete", {
            UserId: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to no delete manager for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedManagers = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error no deleting manager:", error);
      }
    },
    deleteSelected() {
      if (this.selectedManagers.length === 0) {
        this.toast.error("원하시는 삭제 담당자 리스트를 선택해주세요.", {
          position: "top-center", // 메시지 위치
          timeout: 1500, // 3초 동안 표시
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true, // 진행 표시줄 숨기기
          closeButton: false, // X 버튼 숨기기
        });
        return;
      }
      if (confirm("선택된 담당자 항목을 삭제하시겠습니까?")) {
        this.DeleteManager(this.selectedManagers);
      }
    },
    async DeleteManager(selectedIds) {
      try {
        const promises = selectedIds.map(async (id) => {
          return apiClient.post("/api/Manager/manager-delete", {
            UserId: id,
            LoginId: localStorage.getItem("userid"),
          });
        });

        const responses = await Promise.all(promises);

        responses.forEach((response, index) => {
          if (response.status !== 200) {
            console.error(
              `Failed to delete manager for ID ${selectedIds[index]}`
            );
          }
        });
        // 상태 업데이트 후 선택된 항목 초기화
        this.selectedManagers = [];
        this.allSelected = false; // thead 체크박스 해제
        // 데이터 다시 가져오기
        this.fetchData();
      } catch (error) {
        console.error("Error deleting manager:", error);
      }
    },
    handleItemsPerPageChange() {
      this.currentPage = 1; // itemsPerPage 변경 시 페이지를 1로 리셋
      this.managerList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.managerList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.managerList = []; // 페이지 변경 시 목록 초기화
        this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
      }
    },
    nextPageGroup() {
      this.currentPage = this.totalPages;
      this.managerList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    prevPageGroup() {
      this.currentPage = 1;
      this.managerList = []; // 페이지 변경 시 목록 초기화
      this.fetchData(); // 페이지가 변경될 때마다 데이터 다시 가져오기
    },
    goToPage(page) {
      this.currentPage = page;
      this.managerList = []; // 페이지 변경 시 목록 초기화
      this.fetchData();
    },
    goToManagerAdd() {
      this.$router.push({ path: "/managers/add" });
    },
    updateStatus(status) {
      this.SearchStatus = status;
      this.currentPage = 1; // 상태 변경 시 페이지를 1로 리셋
      this.managerList = []; // 회의실 룸 목록 초기화
      this.fetchData();
    },
    async handleCompanyChange() {
      this.currentPage = 1; // 회사 변경 시 페이지를 1로 리셋
      this.fetchData(); // 회사 선택 시 데이터 갱신
    },
    async fetchCompanies() {
      try {
        const searchParam = {
          SearchType: "",
          SearchKey: "",
          SearchActiveStatus: "A", // 진열상태별 값 추가
          OrderType: "companyName_asc",
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Company/all-company-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.companies = Array.isArray(data.data) ? data.data : [];
          console.log(this.companies);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    async fetchData() {
      try {
        let v_companyId = "";

        if (this.userType === "M") {
          v_companyId =
            this.selectedCompany === "0" ? "0" : this.selectedCompany;
        } else {
          v_companyId = "0";
        }

        const searchParam = {
          Page: this.currentPage,
          PageSize: this.itemsPerPage,
          OrderType: this.OrderType,
          SearchActiveStatus: this.SearchStatus, // 상태별 값 추가
          SearchType: this.SearchType,
          SearchKey: this.SearchKey,
          CompanyId: v_companyId, // 전체보기 시 빈 문자열로 설정
          LoginUserId: localStorage.getItem("userid"),
        };

        const response = await apiClient.post(
          "/api/Manager/all-manager-list",
          searchParam
        );

        if (response.status === 200) {
          console.log(response.data);
          const data = JSON.parse(response.data);
          console.log(data);
          this.managerList = Array.isArray(data.data) ? data.data : [];
          this.totalManagers = data.meta.totalCount || 0;
        }
      } catch (error) {
        console.error("Error fetching managers list data:", error);
      }
    },
  },
  mounted() {
    this.fetchCompanies(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchData();
  },
};
</script>
